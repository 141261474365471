/* eslint-disable vue/valid-v-bind */
<template>
  <div>
    <message v-show="page"></message>
    <div class="settingStart">
      <button @click="page = false" v-show="page">开始配置</button>
    </div>
    <div v-if="!page">
      <div>
        <div class="headerBox">
          <el-steps :active="active == 5 ? 5 : active - 1" align-center>
            <el-step title="授权微信认证服务号"></el-step>
            <el-step title="配置业务域名"></el-step>
            <el-step title="设置支付信息"></el-step>
            <el-step title="验证支付信息"></el-step>
            <el-step title="完成"></el-step>
          </el-steps>
        </div>
        <div class="describe" v-if="active == 1">
          <p>您授权的服务号必须满足一下条件:</p>
          <div class="describeBox">
            <li>该服务号为"已认证服务号"</li>
            <li>
              <el-checkbox v-model="checkedOne" @change="checkedClick">已拥有微信认证服务号</el-checkbox>
            </li>
            <li @click="toWx">立即申请</li>
          </div>
          <div class="describeBox">
            <li>该服务号已开通"微信支付"</li>
            <li>
              <el-checkbox v-model="checkedTwo" @change="checkedClick">已开通微信支付</el-checkbox>
            </li>
            <li @click="payWx">立即开通</li>
          </div>
          <p>
            为了保证所有功能正常,授权公众号时请保持默认选择,把权限统一授权给叮叮开课。
          </p>
          <p>授权服务号后，您的用户信息将会发生变更。</p>
          <p>您一旦授权自己的服务号后不可更换。</p>
          <template v-if="auth_status == 1">
            <div class="auth-status-cbk">
              <div :class="[buttom == true ? 'isTrue' : 'isFalse']" @click="buttom ? ((active = 2), next()) : ''" style="margin-top:0;">
                下一步
              </div>
              <div class="auth-jgInfo">
                <img class="logo" :src="jgInfo.logo" alt="" />
                <div class="info">
                  <div class="name">{{ jgInfo.name }}</div>
                  <div class="scopeTime">
                    {{ jgInfo.scopeTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}
                  </div>
                </div>
              </div>
            </div>
            <p class="test">
              已授权，请点击下一步
            </p>
          </template>
          <template v-else>
            <div :class="[buttom == true ? 'isTrue' : 'isFalse']" @click="wxTo">
              立即授权
            </div>
            <p class="test">
              勾选上方两项内容后,点击【立即授权】，会从新的标签页打开授权页，让公众号管理员扫描二维码并确认
            </p>
          </template>
        </div>
        <div class="describe" v-if="active == 2">
          <p>1.登录微信公众号平台,下载微信业务域名文件</p>
          <div class="btnTo pointer" @click="toWx">立即前往</div>
          <p class="p">2.完成下载后,点击按钮上传已下载的域名文件</p>
          <div class="fileUp">
            <div class="btnTo pointer" @click="fileClick">{{ fileName ? '重新' : '点击' }}上传</div>
            <div>{{ fileName }}</div>
          </div>
          <input type="file" v-show="false" ref="inputer" @change="addImg" accept="text/plain" />
          <p class="p">3.复制域名前往微信公众号后台填写</p>
          <div class="boxButtom">
            <button class="btnTo copy-btn pointer" :data-clipboard-text="domain">
              点击复制
            </button>
            <span>{{ domain }}</span>
          </div>
        </div>
        <div v-if="active == 3">
          <div class="inputBox">
            <div><p>微信商户号</p></div>
            <div>
              <el-input v-model.trim="wxValue" placeholder="请输入微信商户号" maxlength="50" show-word-limit></el-input>
            </div>
          </div>
          <div class="inputBoxs">
            <div><p>商户API秘钥</p></div>
            <div>
              <el-input v-model.trim="wxkey" placeholder="请输入商户API秘钥" maxlength="50" show-word-limit></el-input>
            </div>
          </div>
          <div class="mesBox">
            <div>支付目录授权(https:)</div>
            <button class="copy-btn pointer" :data-clipboard-text="domain">
              点击复制
            </button>
            <div>{{ domain }}</div>
          </div>
        </div>
        <div v-if="active == 4">
          <div class="qrCodeBox">
            <p>扫码支付0.01元完成授权</p>
            <p>此操作为验证支付信息配置是否正确,费用不予退还</p>
            <div>
              <img :src="qrCodeUrl" alt="" />
            </div>
          </div>
        </div>
        <div v-if="active == 5" style="padding-bottom: 266px;">
          <div class="nameBox">
            <div class="imgBox">
              <img :src="jgInfo.logo" alt="" />
            </div>
            <div class="messageTitleBox">
              <div>{{ jgInfo.name }}</div>
              <div>授权时间{{ jgInfo.scopeTime | formatTimeStamp('yyyy-MM-dd hh:mm') }}</div>
              <div class="pointer" @click="to">修改配置</div>
            </div>
          </div>
        </div>
        <div class="pageButtom" v-if="active != 5 && active != 1">
          <div @click="back">上一步</div>
          <div @click="next(active)">下一步</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import message from './message'
import Clipboard from 'clipboard'

export default {
  components: { message },

  data() {
    return {
      active: 1,
      page: true,
      checkedOne: false,
      checkedTwo: false,
      buttom: false,
      wxValue: '',
      wxkey: '',
      fileName: '',
      token: '',
      auth_url: '',
      domain: '',
      qrCodeUrl: '',
      jgInfo: {
        name: '',
        logo: '',
        scopeTime: ''
      },
      auth_status: 0
    }
  },

  mounted() {
    this.init()
    this.clipboardInit()
  },

  methods: {
    // 复制
    clipboardInit() {
      const self = this
      const clipboard = new Clipboard('.copy-btn')
      clipboard.on('success', function() {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success'
        })
      })
      clipboard.on('error', function() {
        self.$root.prompt('复制失败')
      })
    },

    init() {
      const _this = this

      this.setJgInfo(() => {
        _this.$http({
          name: 'jigouAuth1',
          url: '/User/jigouAuth',
          isMultipleSelf: 'replacePrev',
          callback({ data: { auth_status } }) {
            if (auth_status != 1) {
              _this.active = 1
              return
            }

            // 授权状态
            _this.auth_status = 1

            _this.buttom = true
            _this.checkedOne = true
            _this.checkedTwo = true

            if (_this.wxValue && _this.wxkey) {
              _this.active = 5
            } else if (_this.fileName) {
              _this.active = 3
            }
          }
        })
      })
    },

    next(active) {
      switch (active) {
        case 1:
          this.One()
          break
        case 2:
          this.Two()
          break
        case 3:
          this.Three()
          break
        case 4:
          this.four()
          break
        case 5:
          this.active = 1
          break
      }
    },

    One() {
      const _this = this
      if (_this.checkedOne == true && _this.checkedTwo == true) {
        _this.active = 2
      }
    },

    Two() {
      if (this.fileName != '') {
        this.active = 3
      } else {
        this.$root.prompt('请先上传域名文件！')
      }
    },

    Three() {
      if (this.active == 3 && this.wxkey != '' && this.wxValue != '') {
        this.active = 4
        this.savePayConfig()
      }
    },

    four() {
      if (this.active == 4 && this.qrCodeUrl != '') {
        this.init()
      } else {
        this.$root.prompt('请先填写内容')
      }
    },

    back() {
      this.active -= 1
      if (this.active <= 1) {
        this.active = 1
      }
    },

    checkedClick() {
      this.buttom = this.checkedOne === true && this.checkedTwo === true
    },

    toWx() {
      const url = 'https://mp.weixin.qq.com/'
      this.$store.dispatch('open', url)
    },

    payWx() {
      const url = 'https://pay.weixin.qq.com/index.php/core/home/login?return_url=%2F'
      this.$store.dispatch('open', url)
    },

    to() {
      this.active = 1
    },

    fileClick() {
      this.$refs.inputer.dispatchEvent(new MouseEvent('click'))
    },

    addImg() {
      const _this = this
      let file = _this.$refs.inputer.files[0]
      let param = new FormData()
      param.append('file', file)
      var request = new XMLHttpRequest()

      request.open('POST', '/WxConfig/uploadDomainFile')
      request.send(param)
      request.onload = () => {
        const response = JSON.parse(request.response) //response就是服务器返回的信息
        _this.fileName = response
      }
      _this.$refs.inputer.value = ''
    },

    wxTo(seep) {
      const _this = this
      if (_this.checkedOne == true && _this.checkedTwo == true) {
        if (!this.isPcWx && !this.$store.state.isWindowsApp) {
          const url = decodeURIComponent(_this.auth_url)
          _this.$store.dispatch('open', url)
        }
        setTimeout(
          () => {
            _this.$confirm('请在新窗口内完成微信服务号授权', '提示', {
              confirmButtonText: '完成授权',
              cancelButtonText: '重试',
              closeOnClickModal: false,
              distinguishCancelAndClose: true,
              beforeClose: (action, instance, done) => {
                if (action === 'confirm') {
                  // 验证是否授权成功
                  _this.$http({
                    name: 'jigouAuth2',
                    url: '/User/jigouAuth',
                    isMultipleSelf: 'replacePrev',
                    callback({ data: { auth_status } }) {
                      if (auth_status == 1) {
                        _this.setJgInfo(() => {
                          // 授权状态
                          _this.auth_status = 1
                          _this.active = 2
                          _this.next()
                        })
                        done()
                      } else {
                        _this.$root.prompt('授权失败，请重试')
                      }
                    }
                  })
                } else if (action === 'cancel') {
                  done()
                  _this.wxTo(true)
                } else {
                  done()
                }
              }
            })
          },
          typeof seep === 'boolean' ? 300 : 0
        )
      }
    },

    // 设置机构信息
    setJgInfo(cbk) {
      const _this = this

      _this.$http({
        name: 'verifyAuthStatus',
        url: '/WxConfig/getConfigInfo',
        isMultipleSelf: 'replacePrev',
        callback({
          data: {
            auth_url,
            third_info: { sub_mch_id, nick_name, pay_key, domain, domain_file_url, head_img, add_time }
          }
        }) {
          // 授权域名
          _this.auth_url = auth_url || ''
          // 域名文件
          _this.fileName = domain_file_url || ''
          // 微信商户号
          _this.wxValue = sub_mch_id || ''
          // 商户API密钥
          _this.wxkey = pay_key || ''
          // 支付授权目录 复制域名前往微信后台填写
          _this.domain = domain ? `${domain}/` : ''
          // 授权机构信息
          _this.jgInfo = {
            logo: head_img || '',
            name: nick_name || '',
            scopeTime: add_time || ''
          }
          cbk && cbk()
        }
      })
    },

    savePayConfig() {
      const _this = this
      _this.$http({
        name: 'savePayConfig',
        url: '/WxConfig/savePayConfig',
        data: {
          sub_mch_id: _this.wxValue,
          pay_key: _this.wxkey,
          domain: _this.domain
        },
        callback(res) {
          _this.qrCodeUrl = res.data.qrcode
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.copy-btn {
  border: 0;
}
.pointer {
  cursor: pointer;
}
.settingStart {
  padding-bottom: 73px;
  text-align: center;
  button {
    border: 0;
    width: 220px;
    height: 46px;
    cursor: pointer;
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    text-align: center;
    line-height: 46px;
    display: inline-block;
    background: rgba(10, 162, 155, 1);
  }
}
.headerBox {
  width: 100%;
  margin: auto;
  margin-top: 40px;
}
.describe {
  margin-left: 87px;
  margin-top: 39px;
  margin-bottom: 153px;
  font-size: 14px;
  color: #333333;
  line-height: 28px;
}
.describeBox {
  display: flex;
  // margin-top: 20px;
  li:nth-child(2) {
    margin-left: 30px;
    font-size: 14px;
  }
  li:nth-child(3) {
    margin-left: 30px;
    color: rgba(10, 162, 155, 1);
    cursor: pointer;
  }
}
.isFalse {
  width: 220px;
  height: 46px;
  background: rgba(10, 162, 155, 1);
  border-radius: 2px;
  opacity: 0.3;
  margin-top: 30px;
  text-align: center;
  line-height: 46px;
  color: #fff;
}
.isTrue {
  width: 220px;
  height: 46px;
  background: rgba(10, 162, 155, 1);
  border-radius: 2px;
  // opacity: 0.3;
  margin-top: 30px;
  text-align: center;
  line-height: 46px;
  color: #fff;
  cursor: pointer;
}
.test {
  margin-top: 10px;
  color: #ff3535;

  font-size: 12px;
}
.btnTo {
  width: 150px;
  height: 40px;
  background: rgba(10, 162, 155, 1);
  border-radius: 2px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-top: 20px;
}
.p {
  margin-top: 20px;
}
.boxButtom {
  display: flex;
  span {
    margin-top: 26px;
    margin-left: 20px;
  }
}
.pageButtom {
  display: flex;
  width: 100%;
  height: 50px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);
  div:nth-child(1) {
    width: 100px;
    height: 36px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px solid rgba(237, 237, 237, 1);
    font-size: 14px;

    color: rgba(102, 102, 102, 1);
    text-align: center;
    line-height: 36px;
    // margin: auto;
    margin-left: 43%;
    margin-top: 5px;
    cursor: pointer;
  }
  div:nth-child(2) {
    width: 100px;
    height: 36px;
    background: rgba(10, 162, 155, 1);
    border-radius: 4px;
    border: 1px solid rgba(237, 237, 237, 1);
    font-size: 14px;

    color: #fff;
    text-align: center;
    line-height: 36px;
    margin-left: 20px;
    margin-top: 5px;
    // margin: auto;
    cursor: pointer;
  }
}
.inputBox {
  display: flex;
  margin-left: 40px;
  margin-top: 50px;
  width: 100%;
  div:nth-child(1) {
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    height: 40px;
    p {
      height: 40px;
      line-height: 40px;
    }
  }
  div:nth-child(2) {
    margin-left: 70px;
    width: 60%;
  }
}
.inputBoxs {
  display: flex;
  margin-left: 40px;
  margin-top: 50px;
  width: 100%;
  div:nth-child(1) {
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    height: 40px;
    p {
      height: 40px;
      line-height: 40px;
    }
  }
  div:nth-child(2) {
    margin-left: 65px;
    width: 60%;
  }
}
.mesBox {
  display: flex;
  // margin-top: 40px;
  margin-left: 40px;
  padding-bottom: 88px;
  div:nth-child(1) {
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    margin-top: 40px;
  }
  button:nth-child(2) {
    width: 150px;
    height: 40px;
    background: rgba(10, 162, 155, 1);
    border-radius: 2px;
    font-size: 13px;

    font-weight: bold;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    line-height: 40px;
    margin-left: 25px;
    margin-top: 30px;
  }
  div:nth-child(3) {
    width: 148px;
    height: 24px;
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    line-height: 24px;
    margin-left: 25px;
    margin-top: 35px;
  }
}
.qrCodeBox {
  padding-bottom: 60px;
  p:nth-child(1) {
    font-size: 28px;

    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-top: 68px;
  }
  p:nth-child(2) {
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-top: 30px;
  }
  div img {
    margin: auto;
    width: 188px;
    height: 188px;
    margin-top: 40px;
  }
}
.nameBox {
  display: flex;
  margin-left: 40%;
  margin-top: 90px;
  width: 360px;
  height: 154px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  border: 1px solid rgba(222, 222, 222, 1);
  .imgBox {
    width: 50px;
    height: 50px;
    margin-left: 29px;
    margin-top: 30px;
    margin-right: 19px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .messageTitleBox {
    div:nth-child(1) {
      height: 21px;
      font-size: 16px;

      color: rgba(51, 51, 51, 1);
      line-height: 21px;
      margin-top: 20px;
    }
    div:nth-child(2) {
      height: 16px;
      font-size: 12px;

      color: rgba(153, 153, 153, 1);
      line-height: 16px;
      margin-top: 10px;
    }
    div:nth-child(3) {
      margin-top: 34px;
      width: 80px;
      height: 32px;
      background: rgba(10, 162, 155, 1);
      border-radius: 2px;
      font-size: 13px;

      font-weight: bold;
      color: rgba(255, 255, 255, 1);
      line-height: 32px;
      text-align: center;
    }
  }
}
.fileUp {
  display: flex;
  div:nth-child(2) {
    margin-left: 30px;
    height: 60px;
    line-height: 75px;
  }
}
.auth-status-cbk {
  display: flex;
  margin-top: 30px;
  align-items: center;
  .auth-jgInfo {
    margin-left: 30px;
    display: flex;
    align-items: center;
    .logo {
      width: 50px;
      height: 50px;
      border-radius: 25px;
    }
    .info {
      margin-left: 10px;
      .name {
        font-size: 16px;
        line-height: 21px;
        color: rgba(51, 51, 51, 1);
      }
      .scopeTime {
        font-size: 12px;
        margin-top: 10px;
        line-height: 16px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }
}
</style>
