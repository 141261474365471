<template>
  <div class="collection">
    <div class="open-close flex-center">
      <el-switch
        @change="openclose"
        v-model="value1"
        active-value="1"
        inactive-value="2"
        class="w32"
      ></el-switch>
      <div class="open-close_contianer2">
        <div class="public-font1">
          <span v-if="value1 == '1'">已开启学员账号绑定</span>
          <span v-else>已关闭学员账号绑定</span>
          <el-popover
            placement="right"
            width="310"
            height="580"
            trigger="hover"
          >
            <div style="margin-left:20px;margin-top:10px;margin-bottom:10px">
              <img
                src="../../assets/img/1.3.4/img_cksl_tg.png"
                width="272"
                alt=""
              />
            </div>
            <el-button
              class="green"
              style="padding-left:0"
              slot="reference"
              type="text"
            >
              查看示例
            </el-button>
          </el-popover>
        </div>
        <div class="public-font2">
          <span>开启后，未绑定手机号的学员会提示绑定手机号，可设置多种绑定账号来源；</span>
          <span class="public-font2_info">
            绑定账号会发送短信验证，单价{{
              collection_phone_price
            }}个叮豆/条，建议保证叮豆充足
          </span>
        </div>
      </div>
    </div>
    <el-divider class="mt20 mb30"></el-divider>
    <div class="contianer" v-if="value1 == 1">
      <div class="public-font1 mb30">采集来源，点击开关可开启和关闭</div>
      <div class="open-close flex-center">
        <el-switch
          @change="switching(1)"
          class="w32"
          v-model="list[0]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>登录网校后</span>
          </div>
          <div class="public-font2">
            <span>
              学员登录网校后会提示绑定手机号（包括手机微信、小程序及电脑网校）；
            </span>

            <span class="public-font2_info">
              注意：调查表功能开启时建议关闭此项，避免弹窗过多
            </span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>

      <div class="open-close flex-center">
        <el-switch
          @change="switching(2)"
          class="w32"
          v-model="list[1]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>报名课程时</span>
          </div>
          <div class="public-font2">
            <span>
              学员报名课程时，支付前会提示绑定手机号，包含免费课、拼团和优惠券报名；
            </span>
            <span class="public-font2_info">
              注意：调查表功能开启时建议关闭此项，避免弹窗过多
            </span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>

      <div class="open-close flex-center">
        <el-switch
          @change="switching(3)"
          class="w32"
          v-model="list[2]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>观看直播时</span>
          </div>
          <div class="public-font2">
            <span>
              学员进入直播间后提示绑定手机号（包括微信H5、小程序，电脑端进入，以及观看回放），口令模式进入无法采集；
            </span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>

      <div class="open-close flex-center">
        <el-switch
          @change="switching(4)"
          class="w32"
          v-model="list[3]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>观看录播时</span>
          </div>
          <div class="public-font2">
            <span>
              学员播放录播视频时会提示绑定手机号（包括在微信H5、小程序、电脑端观看）；
            </span>
            <span class="public-font2_info">
              注意：调查表功能开启时建议关闭此项，避免弹窗过多
            </span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>

      <div class="open-close flex-center">
        <el-switch
          @change="switching(5)"
          class="w32"
          v-model="list[4]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>发起/参与拼团时</span>
          </div>
          <div class="public-font2">
            <span>学员发起拼团、参与拼团会提示绑定手机号；</span>
            <span class="public-font2_info">
              注意：与报名课程时同时开启时，报名课程时的优先级高
            </span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>

      <div class="open-close flex-center">
        <el-switch
          @change="switching(6)"
          class="w32"
          v-model="list[5]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>使用优惠券时</span>
          </div>
          <div class="public-font2">
            <span>学员使用优惠券报名时会提示绑定手机号；</span>
            <span class="public-font2_info">
              注意：与报名课程时同时开启时，报名课程时的优先级高
            </span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>

      <div class="open-close flex-center">
        <el-switch
          @change="switching(7)"
          class="w32"
          v-model="list[6]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>使用兑换码/提取码时</span>
          </div>
          <div class="public-font2">
            <span>学员使用兑换码和提取码获取课程会提示绑定手机号</span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>

      <div class="open-close flex-center">
        <el-switch
          @change="switching(8)"
          class="w32"
          v-model="list[7]"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="open-close_contianer2">
          <div class="public-font1">
            <span>直播间发言时</span>
          </div>
          <div class="public-font2">
            <span>
              学员在直播间发言时会提示绑定手机号（包括在微信H5、小程序、电脑端观看）；
            </span>
            <span class="public-font2_info">
              仅支持直播课的普通模式；口令观看、小班课及极速模式暂不支持
            </span>
          </div>
        </div>
      </div>
      <el-divider class="mt20 mb20"></el-divider>
    </div>
  </div>
</template>
<script>
export default {
  name: 'collection',
  data() {
    return {
      ids: [],

      collection_phone_price: '',

      select: '',

      datelist: [],

      value1: '2',

      list: []
    }
  },

  watch: {
    list: {
      handler(new11) {
        let noclose = new11.filter(f => f == 1).length
        this.$nextTick(() => {
          if (noclose == 0) {
            this.value1 = '2'
          }
        })
      },
      deep: true,
      immediate: true
    }
  },

  created() {
    this.getdata()
  },

  methods: {
    switching(val) {
      this.$http({
        url: '/collectionPhone/setCollection',
        data: {
          id: this.ids[val - 1],
          status: this.list[val - 1]
        },
        callback: () => {},
        error: () => {}
      })
    },

    openclose() {
      this.$http({
        url: '/collectionPhone/setCollection',
        data: {
          is_collection_phone: this.value1
        },
        callback: () => {
          this.getdata()
        },
        error: () => {}
      })
    },

    getdata() {
      this.$http({
        url: '/collectionPhone/collectionSettings',
        callback: ({
          data: { is_collection_phone, collectionInfo, collection_phone_price }
        }) => {
          this.collection_phone_price = collection_phone_price
          this.datelist = collectionInfo
          this.value1 = is_collection_phone
          this.select = collectionInfo.filter(f => f.status == 1).length
          this.list = collectionInfo.map(m => m.status)
          this.ids = collectionInfo.map(m => m.id)
        },
        error: () => {}
      })
    }
  }
}
</script>
<style lang="scss">
.collection {
  padding: 20px;
  padding-top: 10px;
  .el-button--text {
    padding: 0;
  }

  .public-font1 {
    justify-content: space-between;
    font-size: 14px;
    color: rgba(51, 51, 51, 1);
    line-height: 14px;
    display: flex;
  }
  .public-font2 {
    font-size: 12px;
    margin-top: 14px;
    color: rgba(153, 153, 153, 1);
    line-height: 12px;
    .public-font2_info {
      color: #ff3535;
    }
  }
  .w32 {
    width: 32px;
  }
  .open-close_contianer2 {
    margin-left: 30px;
    width: 100%;
  }
}
.el-popover {
  padding: 0;
}
</style>
