<template>
  <div class="container">
    <p class="content">1.开通个人用户收款账户后，机构订单结算金额进入叮叮可提现账户，订单结算周期为T+7,您可随时提现账户余额至绑定的银行卡</p>
    <p class="content">2.收款额度：正常情况下，每日收款额度为1万元，如果交易表现良好，可调整到10-20万元/日，但如交易异常，系统将自动关闭代收款模式</p>
    <p class="content">3.开通即默认同意<a class="green cp" @click="toProtocol(4)">《叮叮开课代收款项代收服务协议》</a></p>
    <p class="content">4.提现收取3%-10%手续费，详情参考<a class="green cp" @click="toProtocol(4)">《叮叮开课代收款项代收服务协议》</a></p>
    <p class="content">5.提交提现申请后款项在7个工作日内到达您选择的银行账号</p>
    <p class="title">个人信息</p>
    <el-divider></el-divider>
    <credentials ref="credentials" :isEdit="isEdit" type="individual" :info="info"></credentials>
    <p class="title">经营信息</p>
    <el-divider></el-divider>
    <personInfo ref="personInfo" :isEdit="isEdit" :info="info"></personInfo>
    <p class="title">银行卡信息<span class="sub">提现使用，请准确填写</span></p>
    <el-divider></el-divider>
    <bankCardsInfo ref="bankCardsInfo" :isEdit="isEdit" :info="info"></bankCardsInfo>
    <el-button ref="yz_btn" style="margin-left: 15px;" type="success" @click="submit">提交申请</el-button>
  </div>
</template>

<script>
import credentials from './components/credentials'
import personInfo from './components/personInfo'
import bankCardsInfo from './components/bankCardsInfo'
export default {
  components: {
    credentials,
    personInfo,
    bankCardsInfo,
  },
  data() {
    return {
      isEdit: false,
      form: {
        courseTime: [],
      },
      info: {},
    }
  },
  async created() {
    // this.getActivation()
    console.log(this.$route);
    const params = this.$route.params
    if (params.edit) {
      await this.getActivation()
      this.isEdit = params.edit
    }
  },
  methods: {
    jump(type) {
      switch (type) {
        case 2:
          // 微信支付个人用户
          this.$router.push('/setting/paymentSetting/individual')
          break;
      }
    },

    async getActivation() {
      await this.$http({
        name: 'getActivation',
        url: '/Information/getActivation',
        data: {},
        callback: ({ data }) => {
          console.log(data);
          this.info = data
          this.$nextTick(() => {
            this.$refs.credentials.setForm()
            this.$refs.personInfo.setForm()
            this.$refs.bankCardsInfo.setForm()
          })
        },
      })
    },
    toProtocol(val) {
      const { href } = this.$router.resolve({
        path: '/protocol',
        query: {
          type: val,
        },
      })
      this.protocolHref = href
      this.$store.dispatch('open', this.protocolHref)
    },
    async submit() {
      const credentialsInfo = await this.$refs.credentials.submit()
      const personInfoInfo = await this.$refs.personInfo.submit()
      const bankCardsInfoInfo = await this.$refs.bankCardsInfo.submit()
      if (credentialsInfo && personInfoInfo && bankCardsInfoInfo) {
        const obj = Object.assign(credentialsInfo, personInfoInfo, bankCardsInfoInfo)
        const info = Object.assign({}, obj, {
          id_photo_back: obj.imgs[0],
          id_photo_front: obj.imgs[1],
          id_start_time: Number(obj.id_long_term) == 1 ? (obj.date[0] / 1000) : 0,
          id_end_time: Number(obj.id_long_term) == 1 ? (obj.date[1] / 1000) : 0,
          contacts_address: obj.area.concat([obj.contacts_address]),
        })
        console.log(info);
        this.$http({
          name: 'activation',
          url: '/Information/activation',
          data: info,
          callback: () => {
            this.$router.push({
              name: 'business',
              params: { type: '2' }
            })
          },
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.cp {
  cursor: pointer;
}

.el-button {
  width: 152px;
  margin-left: 30px;
  color: #fff;
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.el-button:hover {
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.el-button:focus {
  background-color: #0aa29b;
  border-color: #0aa29b;
}

.container {
  padding: 20px;

  .title {
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    font-weight: bold;
    font-size: 16px;
    color: #333333;
    line-height: 21px;
    text-align: left;
    font-style: normal;
    margin: 30px 0 0 0;

    .sub {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #898989;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      margin-left: 20px;
    }
  }

  .el-divider {
    margin: 5px 0;
  }

  .content {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #666666;
    line-height: 23px;
    text-align: left;
    font-style: normal;
    margin: 5px auto 0 30px;

    .green {
      color: #0AA29B;
    }
  }
}
</style>