<template>
  <div class="roots">
    <div class="header">
      <li>模板消息ID</li>
      <li>类型</li>
      <li>操作</li>
    </div>
    <div class="boxMain" v-show="native != true">
      <li @click="toggleTemplateName">添加模板消息ID</li>
      <li>上课提醒通知</li>
      <li><span>- -</span></li>
    </div>
    <div class="boxMains" v-show="native">
      <li>{{ obj.course_template_id }}</li>
      <li>上课提醒通知</li>
      <li>
        <span @click="toggleTemplateName">编辑&#12288;</span> |
        <span @click="clear">&#12288;清除ID</span>
      </li>
    </div>
    <el-dialog :title="`${obj.course_template_id ? '编辑' : '添加'}模板消息`" :visible.sync="showTemplateName" width="422px" :close-on-click-modal="false">
      <div style="margin:20px 20px;margin-top:0px;">
        <el-input v-model.trim="template_name" maxlength="50" show-word-limit placeholder="请输入模板消息"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleTemplateName">取 消</el-button>
        <el-button type="primary" @click="compile">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      value: '',
      native: false,
      obj: {},
      auth_status: 0,
      template_name: '',
      showTemplateName: false
    }
  },

  created() {
    this.init()
  },

  methods: {
    // 创建编辑模板消息
    toggleTemplateName() {
      if (this.auth_status != 1) {
        this.$root.prompt('需要先关联公众号才可添加模板消息！')
      } else {
        this.showTemplateName = !this.showTemplateName
        this.template_name = this.showTemplateName ? this.obj.course_template_id : ''
      }
    },

    // 保存模板消息
    compile() {
      const _this = this
      const value = _this.template_name.trim()
      if (value) {
        _this.$http({
          name: 'compile',
          url: '/Organ/updateTemplateMessage',
          data: {
            course_template_id: value
          },
          callback() {
            _this.init()
          }
        })
      } else if (!value && _this.obj.course_template_id) {
        // 如果是空数据 且 模板ID存在 就去删除掉
        _this.clearTemplateMsg()
      }
      _this.toggleTemplateName()
    },

    // 删除模板弹窗
    clear() {
      this.$confirm('清除后，上课提醒通知将被停用！', '清除ID', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.clearTemplateMsg()
      })
    },

    // 开始删除模板
    clearTemplateMsg() {
      const _this = this
      _this.$http({
        name: 'del',
        url: '/Organ/delTemplateMessage',
        callback() {
          _this.init()
        }
      })
    },

    // 获取模板数据
    init() {
      let _this = this
      _this.$http({
        name: 'templateMsg',
        url: '/Organ/templateMessage',
        callback({ data }) {
          if (data && data.course_template_id) {
            _this.obj = data
            _this.native = true
          } else {
            _this.obj = {}
            _this.native = false
          }
        },
        error({ code }) {
          if (code == 204) {
            _this.obj = {}
            _this.native = false
          }
        },
        complete(e) {
          if (e && e.data && e.data.auth_status) {
            _this.auth_status = e.data.auth_status
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.roots {
  width: 100%;
  padding-bottom: 94px;
}
.header {
  margin: 0 20px;
  height: 42px;
  background: rgba(245, 245, 245, 1);
  border-radius: 1px;
  display: flex;
  line-height: 42px;
  font-size: 14px;

  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  li:nth-child(1) {
    width: 50%;
    padding-left: 20px;
  }
  li:nth-child(2) {
    width: 30%;
  }
  li:nth-child(3) {
    width: 20%;
    text-align: right;
    margin-right: 20px;
  }
}
.boxMain {
  padding-right: 20px;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  display: flex;
  align-items: center;
  li:nth-child(1) {
    width: 50%;
    font-size: 14px;
    padding: 25px 0 20px 40px;
    color: rgba(10, 162, 155, 1);
    cursor: pointer;
  }
  li:nth-child(2) {
    width: 30%;
    font-size: 14px;
  }
  li:nth-child(3) {
    width: 20%;
    text-align: right;
    margin-right: 20px;
    font-size: 14px;

    color: rgba(10, 162, 155, 1);
  }
}
.boxMains {
  margin: 0 20px;
  border-bottom: 1px solid rgba(234, 234, 234, 1);
  display: flex;
  margin-top: 25px;
  padding: 0 20px 25px;
  li:nth-child(1) {
    width: 50%;
    font-size: 14px;

    // color: rgba(10, 162, 155, 1);
    // cursor: pointer;
  }
  li:nth-child(2) {
    width: 30%;
    font-size: 14px;
  }
  li:nth-child(3) {
    width: 20%;
    text-align: right;
    font-size: 14px;

    color: rgba(10, 162, 155, 1);
    cursor: pointer;
  }
}
.el-message-box__btns {
  button:nth-child(1):focus,
  button:nth-child(1):hover {
    color: #0aa29b !important;
    border-color: #0aa29b !important;
    background-color: #ecf5ff !important;
  }
}
/deep/ .el-dialog__body {
  padding: 0;
}
</style>
