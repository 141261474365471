<template>
  <div>
    <div class="titleHeader">
      <p>店铺认证</p>
    </div>
    <div class="schsoolName">
      <div>店铺认证</div>
      <div>
        <li :class="`${certStatus==3?'color-red':''}`" v-if="certStatus==3">认证失败</li>
        <li v-if="certStatus!=3">
          {{ certName }}
          <span v-if="entity_type==1">{{ certInfo.id_name }}</span>
          <span v-if="entity_type!=1">{{ merchant_name }}</span>
        </li>
        <li>
          <span v-if="certStatus==0" @click="jumpToCertification(1)">立即认证</span>
          <span v-else>
            <span @click="jumpToCertification(2)">查看认证</span>
            <span class="pl15" @click="jumpToCertification(3)" v-if="certStatus==2">修改认证</span>
          </span>
        </li>
      </div>
    </div>
    <div class="titleHeader">
      <p>基本信息</p>
    </div>
    <div class="message">
      <div>网校logo</div>
      <div class="logocontain">
        <li>
          <img :src="baseInfo.jg_logo" alt />
        </li>
        <li class="settingbtn">
          <!-- <uploadImg
            :isSortIcon="true"
            :isShowLittleIcon="false"
            ext="?imageView2/1/w/200/h/200"
            @getResultImg="getResultImg"
          > -->
          <changePhotoimg
            :size="2"
            :isshow="true"
            @complete="complete"
            class="changePhotoimg"
          ></changePhotoimg>
          设置
          <!-- </uploadImg> -->
        </li>
      </div>
    </div>
    <div class="schsoolName">
      <div>网校名称</div>
      <div>
        <li>{{ baseInfo.title }}</li>
        <li @click="toggleSchoolName">设置</li>
      </div>
    </div>
    <div class="schsoolName">
      <div>网校链接</div>
      <div>
        <li>{{ 'https://' + baseInfo.domain }}</li>
        <li
          class="copy-btn"
          :data-clipboard-text="`https://` + baseInfo.domain"
        >
          复制链接
        </li>
      </div>
    </div>
    <div class="schsoolBrief">
      <div>网校简介</div>
      <div>
        <li>{{ baseInfo.jg_introduce }}</li>
        <li @click="toggleSchoolIntroduce">设置</li>
      </div>
    </div>
    <div class="schsoolBrief">
      <div>敏感词设置</div>
      <div>
        <li>设置敏感词后，学员在直播间发送的内容与敏感词相符，将以*号显示</li>
        <li @click="toggleSensitiveWords">设置</li>
      </div>
    </div>
    <div class="schsoolBrief">
      <div>游客模式</div>
      <div>
        <li>启用后，学员未登录的状态可访问网校，触发报名课程、进入个人中心时，会提示登录；如停用游客模式，需要登录后才可访问网校(仅支持H5网校)</li>
        <li @click="touristModeClick" :class="{red:touristModeDefault == 1}">{{touristModeDefault == 2 ? '启用':'停用'}}</li>
      </div>
    </div>
    <template v-if="isNotDDkk">
      <div class="schsoolName">
        <div>域名备案号</div>
        <div>
          <li>
            {{
              baseInfo.icp ||
              '应工信部要求，网站页面必须有域名备案号的显示及跳转到工信部网站，为保证网校正常运行，请务必填写，填写后会在PC网校底部显示；可前往自主购买域名的平台查看备案号'
            }}
          </li>
          <li @click="toggleRecordNumber">设置</li>
        </div>
      </div>
    </template>
    <!-- <div class="titleName">
      <li>客服设置</li>
      <li>可上传二维码,设置微信客服</li>
    </div>
    <div class="custom">
      <div class="customerBox" v-for="(item, index) in customer" :key="index">
        <div class="qrCode">
          <img :src="item.qr_code_url" alt />
        </div>
        <div class="customName">
          <div>{{ item.name }}</div>
          <div>
            <span @click="compileClick(item.customer_id)"
              >编辑&nbsp;&nbsp;</span
            >
            <span>&nbsp;|&nbsp;</span>
            <span @click="delCustomer(item.customer_id, index)"
              >&nbsp;&nbsp;删除</span
            >
          </div>
        </div>
      </div>
      <div class="addService" @click="serviceClick">
        <img src="../../assets/img/1.1.5/img_tjkf@2x.png" alt />
      </div>
    </div>
    <div class="titleHeader">
      <p>关注设置</p>
    </div>
    <div class="childName">
      <div>引导关注公众号/加群/加微信</div>
      <div class="childChecked">
        <li>
          <el-checkbox
            v-model="checkedValueOne"
            text-color="#666666"
            @change="cheked(guideTwo)"
            >报名后</el-checkbox
          >
        </li>
        <li @click="qrCode(2)">设置二维码</li>
      </div>
      <div class="childChecked">
        <li>
          <el-checkbox
            v-model="checkedTwo"
            text-color="#666666"
            @change="cheked(guideOne)"
            >进入直播间前</el-checkbox
          >
        </li>
        <li @click="qrCode(1)">设置二维码</li>
      </div>
      <div class="childChecked">
        <li>
          <el-checkbox
            v-model="checkedThree"
            text-color="#666666"
            @change="cheked(guideThree)"
            >网校首页</el-checkbox
          >
        </li>
        <li @click="qrCode(3)">设置二维码</li>
      </div>
    </div>
    <div class="titleHeader">
      <p>功能配置</p>
    </div>
    <div class="functionConfig">
      <div>
        <li>显示课程销量</li>
        <li>
          <el-radio v-model="is_show_sales" label="1" @change="sales"
            >开启</el-radio
          >
        </li>
        <li>
          <el-radio v-model="is_show_sales" label="2" @change="sales">
            关闭
          </el-radio>
        </li>
        <li>开启后将在课程详情中展示已售数量</li>
      </div>
      <div>
        <li>显示浏览数量</li>
        <li>
          <el-radio v-model="is_show_view" label="1" @change="viewNum">
            开启
          </el-radio>
        </li>
        <li>
          <el-radio v-model="is_show_view" label="2" @change="viewNum">
            关闭
          </el-radio>
        </li>
        <li>开启后将在课程详情中展示浏览量</li>
      </div>
      <div>
        <li>显示在线观看人数</li>
        <li>
          <el-radio
            v-model="is_show_live_number"
            label="1"
            @change="liveNumber"
          >
            开启
          </el-radio>
        </li>
        <li>
          <el-radio v-model="is_show_live_number" label="2" @change="liveNumber"
            >关闭</el-radio
          >
        </li>
        <li>开启后将在直播间展示在线观看人数</li>
      </div>
      <div>
        <li>开启评论内容审核</li>
        <li>
          <el-radio v-model="is_comment_review" label="1" @change="review"
            >开启</el-radio
          >
        </li>
        <li>
          <el-radio v-model="is_comment_review" label="2" @change="review"
            >关闭</el-radio
          >
        </li>
        <li>开启后用户在发布评论时需要在后台审核才显示</li>
      </div>
      <div>
        <li>允许小程序观看</li>
        <li>
          <el-radio v-model="is_applet_play" label="1" @change="play"
            >开启</el-radio
          >
        </li>
        <li>
          <el-radio v-model="is_applet_play" label="2" @change="play"
            >关闭</el-radio
          >
        </li>
        <li>
          开启后在进入直播间前会弹出“叮叮开课直播”小程序二维码引导小程序观看
        </li>
      </div>
      <div>
        <li>绑定手机号</li>
        <li>
          <el-radio v-model="is_binding_mobile" label="1" @change="mobile">
            开启
          </el-radio>
        </li>
        <li>
          <el-radio v-model="is_binding_mobile" label="2" @change="mobile">
            关闭
          </el-radio>
        </li>
        <li>
          开启后进入网校课程直播间都会弹出绑定手机，关闭后则不弹出&nbsp;&nbsp;&nbsp;
          <el-checkbox v-model="checkedValuePhone" text-color="#666666"
            >强制绑定</el-checkbox
          >
        </li>
      </div>
      <div>
        <li>允许手机号登入</li>
        <li>
          <el-radio v-model="is_binding_Phone" label="1" @change="isMobile">
            开启
          </el-radio>
        </li>
        <li>
          <el-radio v-model="is_binding_Phone" label="2" @change="isMobile">
            关闭
          </el-radio>
        </li>
        <li>
          开启后学员可使用手机号登录，如担心学员账号共享，可选择关闭
        </li>
      </div>
    </div>-->
    <div class="pageButtom"></div>
    <!-- <el-dialog
      title="新建客服"
      :visible.sync="customerBox"
      width="60%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="customerDiv">
        <div>引导语</div>
        <div>
          <el-input v-model="introducer" placeholder="请输入引导语"></el-input>
        </div>
      </div>
      <div class="customerDivs">
        <div>客服昵称</div>
        <div>
          <el-input v-model="nickname" placeholder="请输入客服昵称"></el-input>
        </div>
      </div>
      <div class="addcustomer">
        <div>上传二维码</div>
        <div>
          <img :src="customerImgs == '' ? customerImg : customerImgs" alt="" />
        </div>
        <div>
          <li>建议尺寸400*400px，jpg、png格式，图片小于5M</li>
          <li @click="qrCodeFile">选择文件</li>
        </div>
      </div>
      <div class="customerHint">
        提示：如设置了微信群二维码，人数超过100人后无法扫码进群，微信群二维码有效期为7天，请及时更新二维码
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customerBox = false">取 消</el-button>
        <el-button type="primary" @click="addCustomerClick">确 定</el-button>
      </span>
      <input
        type="file"
        ref="inputer"
        v-show="false"
        @change="addImg"
        accept="image/png,image/jpeg,image/gif,image/jpg"
      />
    </el-dialog>
    <el-dialog
      title="编辑客服"
      :visible.sync="customerBoxs"
      width="60%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="customerDiv">
        <div>引导语</div>
        <div>
          <el-input v-model="customerVla" placeholder="请输入引导语"></el-input>
        </div>
      </div>
      <div class="customerDivs">
        <div>客服昵称</div>
        <div>
          <el-input
            v-model="customerName"
            placeholder="请输入客服昵称"
          ></el-input>
        </div>
      </div>
      <div class="addcustomer">
        <div>上传二维码</div>
        <div>
          <img :src="customerImage" alt="" />
        </div>
        <div>
          <li>建议尺寸400*400px，jpg、png格式，图片小于2M</li>
          <li @click="qrCodeFileCompile">选择文件</li>
        </div>
      </div>
      <div class="customerHint">
        提示：如设置了微信群二维码，人数超过100人后无法扫码进群，微信群二维码有效期为7天，请及时更新二维码
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="customerBoxs = false">取 消</el-button>
        <el-button type="primary" @click="compileCustomerClick(customerID)"
          >确 定</el-button
        >
      </span>
      <input
        type="file"
        ref="inputerClipe"
        v-show="false"
        @change="addCompileImg"
        accept="image/png,image/jpeg,image/gif,image/jpg"
      />
    </el-dialog>
    <el-dialog
      title="设置二维码"
      :visible.sync="settingQrCodeBox"
      width="60%"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="customerDiv">
        <div>引导语</div>
        <div>
          <el-input v-model="introducers" placeholder="请输入引导语"></el-input>
        </div>
      </div>
      <div class="addcustomer">
        <div>上传二维码</div>
        <div>
          <img :src="qrCodeImgs == '' ? customerImg : qrCodeImgs" alt="" />
        </div>
        <div>
          <li>建议尺寸400*400px，jpg、png格式，图片小于2M</li>
          <li @click="qrCodeFiles">选择文件</li>
        </div>
      </div>
      <div class="customerHint">
        提示：如设置了微信群二维码，人数超过100人后无法扫码进群，微信群二维码有效期为7天，请及时更新二维码
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="settingQrCodeBox = false">取 消</el-button>
        <el-button type="primary" @click="setQrCodeClick">确 定</el-button>
      </span>
      <input
        type="file"
        ref="inputers"
        v-show="false"
        @change="addImgs"
        accept="image/png,image/jpeg,image/gif,image/jpg"
      />
    </el-dialog>-->
    <el-dialog
      class="dialog"
      title="网校名称"
      :visible.sync="showSchoolName"
      width="422px"
      :close-on-click-modal="false"
    >
      <div style="margin: 20px 20px; margin-top: 0px">
        <el-input
          v-model.trim="schoolName"
          maxlength="12"
          show-word-limit
          placeholder="请输入网校名称"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleSchoolName">取 消</el-button>
        <el-button type="primary" @click="updateSchoolName">完 成</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialog"
      title="域名备案号"
      :visible.sync="showRecordNumber"
      width="422px"
      :close-on-click-modal="false"
    >
      <div style="margin: 20px 20px; margin-top: 0px">
        <el-input
          v-model.trim="recordNumber"
          maxlength="50"
          show-word-limit
          placeholder="请输入，如粤ICP备123456789号"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleRecordNumber">取 消</el-button>
        <el-button type="primary" @click="updateRecordNumber">完 成</el-button>
      </span>
    </el-dialog>
    <el-dialog
      class="dialog"
      title="网校简介"
      :visible.sync="showSchoolIntroduce"
      width="422px"
      :close-on-click-modal="false"
    >
      <div style="margin: 20px 20px; margin-top: 0px">
        <el-input
          v-model="schoolIntroduce"
          maxlength="255"
          show-word-limit
          placeholder="请输入网校简介"
          type="textarea"
          rows="8"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleSchoolIntroduce">取 消</el-button>
        <el-button type="primary" @click="updateSchoolIntroduce">
          完 成
        </el-button>
      </span>
    </el-dialog>
    <!--敏感词设置-->
    <el-dialog
      class="dialog"
      title="敏感词设置"
      :visible.sync="showSensitiveWords"
      width="800px"
      :close-on-click-modal="false"
    >
      <div class="tag-prompt">提示：建议输入10个字符以内，使用回车键添加</div>
      <div class="tags-content" @click.self="$refs.tag.focus()">
        <span
          v-for="(item, index) in words"
          :key="`${item}${index}`"
          class="vt tag"
        >
          <i class="vm text">{{ item }}</i>
          <i class="vm icon" @click="removeSensitiveWords(index)"></i>
        </span>
        <span class="vt send-tag">
          <input
            ref="tag"
            v-focus
            v-model.trim="password"
            placeholder="请输入敏感词"
            @blur="addSensitiveWords({ keyCode: 13, target: {} })"
            @keyup="addSensitiveWords"
          />
        </span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="toggleSensitiveWords">取消</el-button>
        <el-button type="primary" @click="saveSensitiveWords">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import Clipboard from 'clipboard'
import API from '@/request/api'
// import uploadImg from '@/components/UploadOneImg'

export default {
  name: 'schoolSetting',

  components: {
    // uploadImg,
    changePhotoimg,
  },

  data() {
    return {
      // 认证状态
      certStatus:0,
      entity_type:0,
      merchant_name:'',
      certInfo:{},

      status: false,
      baseInfo: {},
      schoolName: '',
      showSchoolName: false,
      schoolIntroduce: '',
      showSchoolIntroduce: false,
      showSensitiveWords: false,

      // 敏感词
      words: [],
      password: '',

      // 上一次的值
      oldValueEmpty: true,

      // 网站备案号
      recordNumber: '',
      showRecordNumber: false,
      touristModeDefault:1,
    }
  },

  computed: {
    isNotDDkk() {
      const domain = this.$store.state.userInfo.pcHomeDomain
      return domain && !/\.dingdingkaike\./.test(domain)
    },
    certName(){
      let str = ''
      switch (this.entity_type) {
        case 1:
          str = '个人认证：'
          break;
        case 2:
          str = '个体工商户认证：'
          break;
        case 3:
          str = '企业认证：'
          break;
        case 4:
          str = '党政、机关及事业单位认证：'
          break;
        case 5:
          str = '其他组织认证：'
          break;
        default:
          str = '未认证'
          break;
      }
      return str
    },
  },

  created() {
    this.init()
    this.getStatus()
    this.getCertInfo()
  },

  mounted() {
    this.clipboardInit()
  },

  methods: {
    complete(val) {
      this.baseInfo.jg_logo = val
      this.$http({
        name: 'savePcSchoolInfo1',
        url: '/SchoolPage/savePcSchoolInfo',
        data: { jg_logo: val },
        callback: () => {
          this.init()
        },
      })
    },
    init() {
      const _this = this
      _this.$http({
        name: 'init',
        url: '/SchoolPage/setPCSchool',
        callback({ data: { baseInfo } }) {
          _this.baseInfo = baseInfo
          _this.words = baseInfo.content ? baseInfo.content.split('&_') : []
          _this.originJgName = baseInfo.title

          _this.touristModeDefault = baseInfo.is_h5_visitor_mode
        },
      })
    },

    // 上传网校logo
    // getResultImg(value) {
    //   this.$http({
    //     name: 'savePcSchoolInfo1',
    //     url: '/SchoolPage/savePcSchoolInfo',
    //     data: { jg_logo: value },
    //     callback: () => {
    //       this.init()
    //     },
    //   })
    // },

    // 复制
    clipboardInit() {
      const self = this
      const clipboard = new Clipboard('.copy-btn')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },

    // 跳转店铺认证
    jumpToCertification(type){
      let certType;
      let entity_type = 0;
      switch (Number(type)) {
          case 1:
              // 开始认证
              certType = 'start'
              break;
          case 2:
            switch (this.certStatus) {
              case 1:
                  // 审核中 进行查看审核
                  certType = 'review'
                  break;
              case 2:
                  // 审核通过 查看审核结果数据 
                  if(this.entity_type == 1) certType = 'check-person'
                  if(this.entity_type != 1) certType = 'check-business'
                  break;
              case 3:
                  // 审核未通过 显示审核失败
                  certType = 'reviewFail'
                  entity_type = this.entity_type
                  break;
              }
              break;
            case 3:
              // 修改认证数据
              switch (this.certStatus) {
                case 1:
                  // 审核中 进行查看审核
                  certType = 'review'
                  break;
                case 2:
                  // 审核通过 修改审核结果数据 
                  certType = 'editCtf'
                  entity_type = this.entity_type
                  break;
                case 3:
                  // 审核未通过 显示审核失败
                  if(this.entity_type == 1) certType = 'edit-person'
                  if(this.entity_type != 1) certType = 'edit-business'
                  break;
              }
              break;
      }
      this.$router.push({
        path:'/setting/school/storeCertification',
        query:{
          certType,
          type:this.certStatus,
          entity_type,
        },
      })
    },

    // 修改机构名称
    toggleSchoolName() {
      this.showSchoolName = !this.showSchoolName
      this.schoolName = this.showSchoolName ? this.baseInfo.title : ''
    },

    updateSchoolName() {
      const _this = this
      if (!_this.schoolName || _this.originJgName === _this.schoolName) {
        _this.showSchoolName = false
      } else {
        _this.$http({
          name: 'savePcSchoolInfo1',
          url: '/SchoolPage/savePcSchoolInfo',
          data: { title: this.schoolName },
          callback() {
            _this.init()
            _this.toggleSchoolName()
          },
        })
      }
    },

    // 修改机构名称
    toggleRecordNumber() {
      this.showRecordNumber = !this.showRecordNumber
      this.recordNumber = this.showRecordNumber ? this.baseInfo.icp : ''
    },

    // 网站备案号
    updateRecordNumber() {
      const _this = this
      _this.$http({
        name: 'savePcSchoolInfo1',
        url: '/SchoolPage/savePcSchoolInfo',
        data: { icp: this.recordNumber },
        callback() {
          _this.init()
          _this.toggleRecordNumber()
        },
      })
    },

    // 修改机构简介
    toggleSchoolIntroduce() {
      this.showSchoolIntroduce = !this.showSchoolIntroduce
      this.schoolIntroduce = this.showSchoolIntroduce
        ? this.baseInfo.jg_introduce
        : ''
    },

    // 敏感词设置
    toggleSensitiveWords() {
      if (this.$store.state.userInfo.version_id > 2) {
        this.showSensitiveWords = !this.showSensitiveWords
        this.password = ''
      } else {
        this.$root.toggleUpdatePopup()
      }
    },

    // 添加 删除敏感词
    addSensitiveWords({ keyCode, target }) {
      if (keyCode === 13 && this.password) {
        if (
          /[^\w|[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0]]/gi.test(
            this.password
          )
        ) {
          this.status = true
          this.$root.prompt('敏感词暂不支持特殊符号，仅支持中文、英文、数字')
          return
        }
        this.words.push(this.password.slice(0, 50))
        this.password = ''
        this.status = false
        this.oldValueEmpty = true
      } else if (keyCode === 8 && this.oldValueEmpty) {
        this.removeSensitiveWords(this.words.length - 1)
        this.status = false
      } else {
        this.status = false
        this.oldValueEmpty = !target.value
      }
    },

    // 请求后台 保存敏感词
    saveSensitiveWords() {
      if (this.status) {
        this.$root.prompt('敏感词暂不支持特殊符号，仅支持中文、英文、数字')
        return
      }
      this.$http({
        name: 'savePcSchoolInfo5',
        url: '/SchoolPage/savePcSchoolInfo',
        data: {
          content: this.words.join('&_'),
        },
        callback: () => {
          this.showSensitiveWords = false
          this.password = ''
        },
      })
    },

    // 删除敏感词
    removeSensitiveWords(index) {
      this.words.splice(index, 1)
    },

    updateSchoolIntroduce() {
      const _this = this
      if (!_this.schoolIntroduce) {
        _this.showSchoolIntroduce = false
      } else {
        _this.$http({
          name: 'savePcSchoolInfo2',
          url: '/SchoolPage/savePcSchoolInfo',
          data: { jg_introduce: this.schoolIntroduce },
          callback() {
            _this.init()
            _this.toggleSchoolIntroduce()
          },
        })
      }
    },
    touristModeClick(){
      if(this.touristModeDefault == 1){
        this.touristMode('停用')
      }else{
         this.touristMode('启用')      
      }
    },
    touristMode(mode){
      this.$confirm(`是否确定${mode}游客模式！`, '温馨提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            callback: action => {
              if (action == 'confirm') {
                //启用、停用游客模式
                this.$http({
                  url: '/schoolPage/setVisitor',
                  data: {
                    is_h5_visitor_mode:mode == '启用' ? 1 : 2
                  },
                  callback: ({ code }) => {
                    console.log(`是否确定${mode}游客模式！`, code)
                    // this.touristModeDefault = !this.touristModeDefault
                    if(this.touristModeDefault == 1){
                      this.touristModeDefault = 2
                    }else{
                      this.touristModeDefault = 1
                    }
                  }
                })
              }
            }
          })
    },
    
    getStatus(){
      // 获取审核状态
      this.$http({
          name: API.certGetStatus_Api.name,
          url: API.certGetStatus_Api.url,
          data: {},
          callback:(res) => {
              if(res.data){
                  const { status,entity_type,merchant_name } = res.data
                  // const status = 2
                  // const entity_type = 4
                  // const merchant_name = 'merchant_name'
                  this.certStatus = Number(status)
                  this.entity_type = Number(entity_type)
                  this.merchant_name = merchant_name
              }
          },
      })
    },
    getCertInfo(){
        // 获取认证数据
        const that = this
        this.$http({
            name: API.certGetActivation_Api.name,
            url: API.certGetActivation_Api.url,
            data: {},
            callback:(res) => {
                that.certInfo = res.data
            },
        })
    },
  },
}
</script>
<style lang="less" scoped>
.el-dialog__body {
  padding: 0 !important;
  margin-left: 0px !important;
  height: 78% !important;
}
.el-message-box__input {
  padding-top: 5px !important;
}
.el-textarea__inner {
  height: 120px !important;
  resize: none !important;
}
.el-textarea__inner:focus {
  border-color: #0aa29b;
}
.el-message-box__btns {
  margin-top: 0px !important;
}
.titleHeader {
  margin-top: 30px;
  margin-left: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid#E2E2E2;
  p {
    font-size: 16px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }
}
.message {
  display: flex;
  margin-left: 20px;
  .logocontain {
    position: relative;
    .changePhotoimg {
      position: absolute;
      right: 0;
      height: 30px !important;
    }
  }
  > div:nth-child(1) {
    margin-top: 40px;
    width: 59px;
    height: 19px;
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    line-height: 19px;
    width: 10%;
  }
  .logocontain {
    display: flex;
    justify-content: space-between;
    // margin-left: 58px;
    border-bottom: 1px solid#E2E2E2;
    padding-bottom: 10px;
    width: 90%;
    > li:nth-child(1) {
      font-size: 14px;
      flex: 1;
      color: rgba(51, 51, 51, 1);
      > img {
        width: 40px;
        height: 40px;
        margin-top: 30px;
        border-radius: 2px;
      }
    }
    .settingbtn {
      padding-right: 24px;
      margin-top: 45px;
      font-size: 14px;

      color: rgba(10, 162, 155, 1);
      cursor: pointer;
    }
  }
}
.schsoolName {
  display: flex;
  margin-left: 20px;
  .color-red {
    color: red !important;
  }
  div:nth-child(1) {
    margin-top: 40px;
    width: 59px;
    height: 19px;
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    line-height: 19px;
    width: 10%;
  }
  div:nth-child(2) {
    display: flex;
    // margin-left: 58px;
    border-bottom: 1px solid#E2E2E2;
    padding-bottom: 10px;
    width: 90%;
    li:nth-child(1) {
      font-size: 14px;

      color: rgba(51, 51, 51, 1);
      width: 50%;
      margin-top: 40px;
    }
    li:nth-child(2) {
      width: 50%;
      text-align: right;
      margin-top: 45px;
      font-size: 14px;

      color: rgba(10, 162, 155, 1);
      margin-right: 24px;
      cursor: pointer;
    }
  }
}
.custom {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
}
.customerBox {
  width: 23%;
  display: flex;
  margin: 10px;
  margin: 20px 60px 5px;
  .qrCode {
    width: 98px;
    height: 98px;
    // margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.customName {
  margin-left: 20px;
  div:nth-child(1) {
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    margin-top: 30px;
  }
  div:nth-child(2) {
    font-size: 14px;

    color: rgba(153, 153, 153, 1);
    text-align: center;
    margin-top: 10px;
    span {
      cursor: pointer;
    }
  }
}
.schsoolBrief {
  display: flex;
  margin-left: 20px;
  div:nth-child(1) {
    margin-top: 40px;
    width: 59px;
    height: 19px;
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    line-height: 19px;
    width: 10%;
  }
  div:nth-child(2) {
    display: flex;
    // margin-left: 58px;
    border-bottom: 1px solid#E2E2E2;
    padding-bottom: 10px;
    width: 90%;
    li:nth-child(1) {
      font-size: 14px;

      color: rgba(51, 51, 51, 1);
      width: 90%;
      margin-top: 40px;
    }
    li:nth-child(2) {
      width: 10%;
      text-align: right;
      margin-top: 45px;
      font-size: 14px;

      color: rgba(10, 162, 155, 1);
      margin-right: 24px;
      cursor: pointer;
    }
  }
}
.titleName {
  margin-top: 30px;
  margin-left: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid#E2E2E2;
  display: flex;
  li:nth-child(1) {
    width: 64px;
    height: 21px;
    font-size: 16px;

    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }
  li:nth-child(2) {
    width: 156px;
    height: 16px;
    font-size: 12px;

    color: rgba(153, 153, 153, 1);
    line-height: 16px;
    margin-left: 21px;
  }
}
.addService {
  width: 98px;
  height: 98px;
  margin-top: 25px;
  margin-left: 60px;
  img {
    width: 100%;
    height: 100%;
  }
}
.childName {
  display: flex;
  margin-left: 20px;
  margin-top: 30px;
  div:nth-child(1) {
    width: 180px;
    height: 19px;
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    line-height: 19px;
  }
}
.childChecked {
  display: flex;
  margin-left: 60px;
  li:nth-child(1) {
    // width: 36px;
    // height: 16px;
    font-size: 12px;

    color: rgba(102, 102, 102, 1);
    // line-height: 16px;
  }
  li:nth-child(2) {
    // width: 60px;
    // height: 16px;
    font-size: 12px;

    color: rgba(10, 162, 155, 1);
    // line-height: 16px;
    margin-top: 3px;
    margin-left: 5px;
    cursor: pointer;
  }
}
.functionConfig {
  width: 100%;
  div {
    display: flex;
    margin-top: 30px;
    margin-left: 20px;
    li:nth-child(1) {
      font-size: 14px;

      color: rgba(51, 51, 51, 1);
      width: 13%;
      margin-top: 4px;
    }
    li:nth-child(2) {
      width: 10%;
      margin-bottom: 2px;
    }
    li:nth-child(3) {
      width: 10%;
    }
    li:nth-child(4) {
      width: 67%;
      height: 16px;
      font-size: 12px;

      color: rgba(51, 51, 51, 1);
      line-height: 16px;
      margin-top: 5px;
    }
  }
}
.pageButtom {
  height: 69px;
  width: 100%;
}
.imgSpi {
  width: 650px;
  height: 400px;
  // border: #67c23a solid 1px;
  margin: auto;
}
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  .cropper {
    width: 300px;
    height: 300px;
    margin-top: 10px;
    margin-left: 20px;
  }
  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
    .preview {
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #cccccc;
      background: #cccccc;
      margin-left: 40px;
    }
  }
}
.footer-btn {
  margin-top: 30px;
  margin-left: 20px;
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  .scope-btn {
    width: 350px;
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    -webkit-justify-content: space-between;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    -webkit-justify-content: center;
  }
  .btn {
    outline: none;
    display: inline-block;
    line-height: 2;
    white-space: nowrap;
    cursor: pointer;
    -webkit-appearance: none;
    text-align: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    margin: 0;
    -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 12px;
    border-radius: 3px;
    color: #fff;
    background-color: #0aa29b;
    border-color: #0aa29b;
  }
}
.textareaBox {
  resize: none;
  width: 98%;
  height: 100px;
}
.customerDiv {
  display: flex;
  div:nth-child(1) {
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
  }
  div:nth-child(2) {
    margin-left: 60px;
    width: 70%;
  }
}
.customerDivs {
  display: flex;
  margin-top: 30px;
  div:nth-child(1) {
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
    margin-left: 20px;
    height: 40px;
    line-height: 40px;
  }
  div:nth-child(2) {
    margin-left: 45px;
    width: 70%;
  }
}
.addcustomer {
  display: flex;
  margin-top: 30px;
  div:nth-child(1) {
    margin-left: 18px;
    font-size: 14px;

    color: rgba(51, 51, 51, 1);
  }
  div:nth-child(2) {
    width: 98px;
    height: 98px;
    margin-left: 56px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  div:nth-child(3) {
    margin-left: 30px;
    li:nth-child(1) {
      font-size: 12px;

      color: rgba(51, 51, 51, 1);
    }
    li:nth-child(2) {
      width: 150px;
      height: 40px;
      background: rgba(10, 162, 155, 1);
      border-radius: 2px;
      text-align: center;
      color: #fff;
      line-height: 40px;
      margin-top: 20px;
    }
  }
}
.customerHint {
  margin-left: 140px;
  margin-top: 20px;
  font-size: 12px;

  color: rgba(153, 153, 153, 1);
  margin-bottom: 120px;
}
.dialog-footer {
  button:nth-child(1):hover {
    color: #0aa29b !important;
    border-color: #0aa29b !important;
    // background-color: #000 !important;
  }
}
.upload-btn {
  button:nth-child(1):hover {
    color: #0aa29b !important;
    border-color: #0aa29b !important;
    // background-color: #000 !important;
  }
}
.scope-btn {
  button:hover {
    color: #0aa29b !important;
    border-color: #0aa29b !important;
    // background-color: #000 !important;
  }
}
.dialog {
  & /deep/ .el-dialog__body {
    padding: 0;
  }
}
/***敏感词***/
.tag-prompt {
  position: absolute;
  font-size: 12px;
  color: rgba(255, 53, 53, 1);
  line-height: 16px;
  left: 125px;
  top: 26px;
}
.tags-content {
  height: 340px;
  background: rgba(255, 255, 255, 1);
  border-radius: 2px;
  border: 1px solid rgba(232, 232, 232, 1);
  width: 95%;
  margin: 0 auto;
  padding: 10px 10px 10px 0;
  box-sizing: border-box;
  overflow: auto;
  .tag {
    font-size: 0;
    border-radius: 4px;
    padding: 7px 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    background: rgba(237, 237, 237, 1);
    border: 1px solid rgba(237, 237, 237, 1);
    .text {
      font-size: 12px;
      line-height: 16px;
      color: rgba(102, 102, 102, 1);
    }
    .icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
      margin-left: 10px;
      background: url('~@ass/img/ico_gbts.png') no-repeat center center;
      background-size: 10px;
    }
    &:hover {
      border-color: #ffadad;
    }
  }
  .send-tag {
    height: 31px;
    margin-left: 10px;
    input {
      border: 0;
      color: #333;
      height: 100%;
      display: block;
      font-size: 14px;
      &::placeholder {
        color: rgba(204, 204, 204, 1);
      }
    }
  }
}
.red{
  color: #FF3535 !important;
}
</style>
