<template>
  <!-- installment -->
  <div class="paymentSetting">
    <div class="zfb-payment">
      <div class="left">
        <div class="t1">
          <span v-if="weChatPayObj.wechat_type==0">微信支付</span>
          <span v-if="weChatPayObj.wechat_type==1">微信支付-个人用户模式<span class="t3">已开通</span></span>
          <span v-if="weChatPayObj.wechat_type==2">微信支付-商户模式<span class="t3">已开通</span></span>
        </div>
        <div class="t2">
          <!-- <p>
            接入微信支付后，机构可以完成自主收款，接入微信支付必须先关联公众号
          </p> -->
          <p>接入微信支付后，学员可直接使用微信付款</p>
        </div>
      </div>
      <template>
        <button class="open-btn disable" v-if="weChatPayObj.wechat_type!=0" @click="toOpenWechat">已开通</button>
        <button class="open-btn" @click="toOpenWechat" v-else>立即开通</button>
      </template>
    </div>

    <div class="zfb-payment">
      <div class="left">
        <div class="t1">支付宝支付</div>
        <div class="t2">
          集成支付宝提供的支付能力，用户支付时，可选择跳转支付宝完成付款，付款后自动跳回。
          轻松享受更全面、更安全的支付服务。
          <span style="color: #ff3535">
            目前在微信里选择支付宝会跳到浏览器支付
          </span>
        </div>
      </div>
      <template v-if="status > 0">
        <button
          class="open-btn disable"
          @click="showPymentCode = true"
          v-if="status == 2"
        >
          已开通
        </button>
        <button class="open-btn" @click="showPymentCode = true" v-else>
          立即开通
        </button>
      </template>
    </div>

    <div class="hb-payment-contain">
      <div class="hb-payment">
        <div class="left">
          <div class="t1">花呗分期支付</div>
          <div class="t2">
            先消费，后按月分期还款的服务，有效减轻学员资金压力，提升大额商品的下单欲望。
            可设置3、6、12期，分期每笔均产生分期手续费，商家可选择买家或商家自己承担（无法共同承担），开通后可创建免息活动，也可以单独配置可分期的商品
          </div>
        </div>
        <template>
          <button
            class="open-btn disable"
            v-if="$store.state.userInfo.is_alipay_flowers == 1"
          >
            已开通
          </button>
          <el-button
            v-else
            @click="CustomerServiceQRCode = true"
            type="primary"
            style="width: 152px; margin-left: 43px"
            size="medium"
          >
            申请开通
          </el-button>
        </template>
      </div>
      <el-button
        type="text"
        @click="
          $store.dispatch(
            'open',
            'https://www.yuque.com/dingdingkaike/doc/orryep'
          )
        "
      >
        帮助文档
      </el-button>
    </div>

    <el-dialog
      width="600px"
      title="开通支付宝支付"
      :visible.sync="showPymentCode"
    >
      <div style="height: 385px">
        <div v-show="status == 2">
          <div class="success-text">恭喜，您的网校已开通支付宝支付</div>
          <div class="el-icon-success success-icon"></div>

          <el-button class="cancelAuth" @click="cancelAuth">取消授权</el-button>
        </div>
        <div v-show="status == 1">
          <div class="pymentCodo-title">
            请使用支付宝App扫码进行授权
            <a
              target="_blank"
              href="https://www.yuque.com/docs/share/6812c721-61b0-4da9-b79d-7f4f376f6a53?#"
              class="auth-help"
            >
              授权流程
            </a>
            <el-button
              class="isAuth"
              type="text"
              @click="isAuthSuccess"
              :loading="isAuth"
            >
              已完成授权
            </el-button>
          </div>
          <div class="pymentCodo-code" ref="qrcode"></div>
          <div class="pymentCodo-url">{{ authUrl }}</div>
          <div class="pymentCodo-btn">
            <el-button class="b1" type="primary" @click="downEwm">
              下载二维码
            </el-button>
            <el-button
              type="primary"
              class="for-copy"
              :data-clipboard-text="authUrl"
            >
              复制链接
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 申请开通花呗支付 -->
    <el-dialog
      :visible.sync="CustomerServiceQRCode"
      :show-close="false"
      class="CustomerServiceQRCode"
      width="518px"
    >
      <div class="hb-diolog">
        <div class="code-title">微信扫码联系客户经理，申请并协助您开通</div>
        <div class="customer-code">
          <img src="~@ass/img/1.4.5.5/img_xsewm.png" alt="" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from '@ass/js/qrcode'
import ClipboardJS from 'clipboard'

export default {
  name: 'paymentSetting',

  data() {
    return {
      isedit: false,

      copyWechatlist: {},

      weChatPayObj: {},

      CustomerServiceQRCode: false,

      status: 0,

      authUrl: '',

      isAuth: false,
      showPymentCode: false,

      businessInfo: {
        isOpen:false,
        isEdit:false,
      },
    }
  },

  computed: {
    isopen() {
      return this.copyWechatlist.sub_key && this.copyWechatlist.appsecret
    },
  },

  watch: {
    async showPymentCode(val) {
      await this.openStatus()
      if (!this.qrcode && val && this.status == 1) {
        this.$http({
          url: '/Alipay/getCode',
          callback: ({ data }) => {
            this.authUrl = data.url
            this.$nextTick(() => {
              this.createQRCode()
              this.initCopy()
            })
          },
        })
      }
    },
  },

  created() {
    this.openStatus()
    this.getWeChatPay()
  },

  methods: {
    // 保存微信支付编辑的数据
    async save() {
      try {
        await this.$http({
          url: '/user/saveSub',
          data: this.weChatPayObj,
        })
        this.isedit = false
        this.getWeChatPay()
      } catch (e) {
        console.alert(e)
      }
    },

    // 开通微信支付
    toOpenWechat() {
      // if (!this.weChatPayObj.appsecret) {
      //   this.$root.prompt('微信商户号不能为空')
      // } else if (!this.weChatPayObj.sub_key) {
      //   this.$root.prompt('微信商户密钥不能为空')
      // } else {
        this.$router.push('/setting/paymentSetting/business')
      // }
    },

    // 获取微信支付的数据
    async getWeChatPay() {
      try {
        const { data } = await this.$http({
          url: '/user/getWeChatPay',
          data: {},
        })
        this.copyWechatlist = _.merge({}, data)
        this.weChatPayObj = data
      } catch (e) {
        console.alert(e)
      }
    },

    // 获取支付宝开通状态
    openStatus() {
      return new Promise((resolve) => {
        this.isAuth = true
        this.$http({
          url: '/Alipay/getAlipayAuth',
          callback: ({ data: { status } }) => {
            this.status = status
            this.isAuth = false
            resolve()
          },
        })
      })
    },

    // 是否已完成授权
    async isAuthSuccess() {
      await this.openStatus()
      if (this.status == 2) {
        this.$refs.qrcode.innerHTML = ''
        this.qrcode.clear()
        this.qrcode = null
      }
    },

    // 创建支付宝授权二维码
    createQRCode() {
      this.qrcode = new QRCode(this.$refs.qrcode, {
        text: this.authUrl,
        width: 160,
        height: 160,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H,
      })
    },

    initCopy() {
      const self = this
      let clipboard = new ClipboardJS('.for-copy')
      clipboard.on('success', function () {
        self.$root.prompt({
          msg: '复制成功',
          type: 'success',
        })
      })
      clipboard.on('error', function () {
        self.$root.prompt('复制失败')
      })
    },

    // 下载二维码
    downEwm() {
      const imgUrl = this.qrcode.getBase64Code()
      if (window.navigator.msSaveOrOpenBlob) {
        var bstr = atob(imgUrl.split(',')[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        var blob = new Blob([u8arr])
        window.navigator.msSaveOrOpenBlob(blob, 'zfb-auth' + '.' + 'png')
      } else {
        // 这里就按照chrome等新版浏览器来处理
        const a = document.createElement('a')
        a.href = imgUrl
        a.setAttribute('download', 'zfb-auth')
        a.click()
      }
    },

    // 取消授权
    async cancelAuth() {
      await this.$confirm(
        '取消后，学员在网校内支付将没有支付宝支付的选项！',
        '取消授权',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal: false,
        }
      )
      this.$http({
        url: '/Alipay/cancel',
        data: {},
        callback: () => {
          this.$root.prompt({
            msg: '取消成功',
            type: 'success',
          })
          this.showPymentCode = false
        },
        error() {
          this.$root.prompt({
            msg: '取消失败',
          })
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.CustomerServiceQRCode {
  .el-dialog__header {
    padding: 0;
    margin: 0;
  }
  .hb-diolog {
    text-align: center;
    .code-title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .customer-code {
      width: 150px;
      height: 148px;
      margin: 50px auto;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
.hb-payment-contain {
  margin: 20px;
  background: #f5f5f5;
  padding: 26px 20px;
  background: #f5f5f5;
  .hb-payment {
    display: flex;
    align-items: center;
  }
}
.zfb-payment {
  display: flex;
  margin: 20px;
  align-items: center;
  background: #f5f5f5;
  padding: 26px 20px;
}
.left {
  flex: 1;
  .t1 {
    font-size: 18px;
    font-weight: bold;
    color: #333333;

  }
  .t2 {
    font-size: 13px;
    color: #333333;
    line-height: 17px;
    margin-top: 20px;
    p {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      .el-input {
        flex: 1;
      }
    }
  }
  .t3 {
    font-size: 12px;
    color: #0AA29B;
    line-height: 16px;
    text-align: right;
    font-style: normal;
    border-radius: 4px;
    border: 1px solid #0AA29B;
    margin-left: 10px;
    padding:3px;
  }
}
.open-btn {
  border: 0;
  width: 152px;
  height: 42px;
  font-size: 17px;
  text-align: center;
  background: #0aa29b;
  color: #ffffff;
  border-radius: 3px;
  margin-left: 44px;
  cursor: pointer;
  &.disable {
    color: #666666;
    background: #e1e1e1;
  }
}

.success-icon {
  color: #50adde;
  font-size: 120px;
  margin: 64px 0 0 0;
  text-align: center;
  width: 100%;
}
.cancelAuth {
  border: 0;
  color: #ff3535;
  background: #f1f1f1;
  margin: 102px auto 0;
  display: block;
  &:hover {
    border: 0;
    color: #ff3535;
    background: #f1f1f1;
  }
}
.success-text {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  text-align: center;
}

.pymentCodo-title {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #333333;
  .isAuth,
  .auth-help {
    cursor: pointer;
    color: #0aa29b;
    margin-left: 10px;
    font-weight: bold;
    font-size: 16px;
    text-decoration: underline;
  }
  .isAuth {
    color: #ff3535;
  }
}
.pymentCodo-code {
  width: 200px;
  height: 200px;
  padding: 20px;
  margin: 10px auto 20px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #dddddd;
}
.pymentCodo-url {
  font-size: 13px;
  color: #666666;
  text-align: center;
  word-break: break-all;
}
.pymentCodo-btn {
  margin-top: 20px;
  text-align: center;
  .el-button {
    width: 152px;
    height: 42px;
    background: #0aa29b;
    border-radius: 3px;
  }
  .b1 {
    color: #333333;
    background: none;
    border: 1px solid #e1e1e1;
  }
}
</style>
