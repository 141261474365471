<template>
  <div class="root">
    <el-tabs v-model="activeName" :before-leave="beforeLeave" v-if="!showChildren">
      <el-tab-pane
        v-if="showSettingSchool"
        label="网校设置"
        name="settingSchool"
      >
        <schoolSetting v-if="activeName == 'settingSchool'"></schoolSetting>
      </el-tab-pane>
      <el-tab-pane
        v-if="showSettingWechat"
        label="关联公众号"
        name="settingWechat"
      >
        <publicCode v-if="activeName == 'settingWechat'"></publicCode>
      </el-tab-pane>
      <el-tab-pane
        v-if="showSchoolTemplate"
        label="模板消息"
        name="schoolTemplate"
      >
        <Msg v-if="activeName == 'schoolTemplate'"></Msg>
      </el-tab-pane>
      <el-tab-pane
        v-if="showSettingCollect"
        label="账号绑定"
        name="settingCollect"
      >
        <collection v-if="activeName == 'settingCollect'"></collection>
      </el-tab-pane>
      <el-tab-pane label="支付设置" name="paymentSetting">
        <paymentSetting v-if="activeName == 'paymentSetting'"></paymentSetting>
      </el-tab-pane>
    </el-tabs>
    <wechatBusiness v-if="this.activeName=='business'"></wechatBusiness>
    <individual v-if="this.activeName=='individual'"></individual>
    <storeCertification v-if="this.activeName=='storeCertification'"></storeCertification>
  </div>
</template>
<script>
import schoolSetting from './schoolSetting'
import publicCode from './publicCode'
import collection from './collection'
import paymentSetting from './paymentSetting'
import wechatBusiness from './wechatBusiness'
import individual from './individual'
import storeCertification from './storeCertification'
import Msg from './msg'
import bg from '@/mixin/background'

export default {
  components: {
    collection,
    paymentSetting,
    schoolSetting,
    wechatBusiness,
    individual,
    storeCertification,
    publicCode,
    Msg,
  },

  mixins: [bg],

  data() {
    return {
      activeName: '',
    }
  },

  watch: {
    $route: {
      handler(to) {
        this.activeName = to.name
      },
      immediate: true,
    },
  },

  computed: {
    showSettingSchool() {
      return this.$store.state.roots.includes(68)
    },

    showSettingWechat() {
      return this.$store.state.roots.includes(49)
    },

    showSchoolTemplate() {
      return this.$store.state.roots.includes(50)
    },

    showSettingCollect() {
      return this.$store.state.roots.includes(77)
    },
    showChildren(){
      return this.activeName=='business'||this.activeName=='individual'||this.activeName=='storeCertification'
    },
  },

  methods: {
    // 点击标签切换前
    beforeLeave(activeName) {
      this.$router.push({
        name: activeName,
      })
    },
  },
}
</script>
<style lang="less" scoped>
.root {
  margin: 20px;
  background: #fff;
}
/deep/ .el-tabs__nav-wrap {
  padding: 0 20px;
}
/deep/ .el-tabs__nav-wrap:after {
  height: 1px;
}
</style>
