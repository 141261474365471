<template>
  <div>
    <div v-show="page">
      <div class="messageBox">
        <p>前提条件</p>
        <div>
          您需要在微信公众平台申请开通“微信认证服务号”，且此服务号需要向微信申请开通“微信支付权限”；
          服务号一经绑定不可更换，绑定后您可以使用自己服务号的微信支付，收入直接进入您的微信支付对应的收款账户；
          您还可以通过此服务号给用户发送服务号通知。
        </div>
        <span @click="toView">查看教程</span>
      </div>
      <div class="step">
        <img src="../../assets/img/1.1.5/img_lcbz.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'message',
  data() {
    return {
      active: -1,
      page: true,
    }
  },
  methods: {
    toView() {
      const url = 'https://www.yuque.com/dingdingkaike/doc/associate'
      this.$store.dispatch('open', url)
    },
  },
}
</script>
<style lang="scss" scoped>
.messageBox {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  height: 186px;
  background: rgba(245, 245, 245, 1);
  border-radius: 2px;
  border: 1px solid rgba(230, 230, 230, 1);
  p {
    width: 60px;
    height: 20px;
    font-size: 15px;

    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 20px;
    margin-top: 20px;
    margin-left: 20px;
  }
  div {
    width: 60%;
    margin-left: 20px;
    margin-top: 14px;
    font-size: 14px;
    height: 72px;
    line-height: 24px;
    color: rgba(51, 51, 51, 1);
  }
  span {
    width: 56px;
    height: 19px;
    font-size: 14px;
    margin-top: 20px;
    color: rgba(10, 162, 155, 1);
    line-height: 19px;
    display: block;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
  }
}
.title {
  text-align: center;
  height: 37px;
  font-size: 28px;
  color: rgba(51, 51, 51, 1);
  line-height: 37px;
  margin-top: 68px;
}
.step {
  width: 716px;
  margin: 68px auto;
  img {
    width: 100%;
  }
}
.buttom {
  width: 220px;
  height: 46px;
  background: rgba(10, 162, 155, 1);
  border-radius: 2px;
  color: #fff;
  text-align: center;
  line-height: 46px;
  margin-top: 70px;
  margin-left: 42%;
}
</style>
